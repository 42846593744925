*, ::before, ::after {
    margin: 0;
    padding: 0;
}

body, html {
    height: 100%;
    width: 100%;
}

body {
    display: flex;
    justify-content: center;
}

#home {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
}

form > * {
    margin: 0 10px;
}

input, select {
    margin: 0 5px;
}

.center-column {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

h2 {
    text-align: center;
}

#arrayAlphabet {
    align-content: stretch;
    align-self: center;
}


.container-letters {
    display: flex;
    flex-direction: row;
}

.container-letter {
    border-left: solid black 4px;
    border-bottom: solid black 4px;
    width: 30px;
    text-align: center;
}


.container-letters > :last-child {
    border-right: solid black 4px;
}

#arrayAlphabet > :first-child > * {
    border-top: solid black 4px;
}

#testZone {
    margin-top: 20px;
}


#question {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#awnser {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-self: center;
}

#awnserInput {
    border: 6px grey solid;
    box-sizing: border-box;
    padding: 5px;
    text-align: center;
}

#awnserInput.bad {
    border: 6px red solid;
}

#awnserInput.good {
    border: 6px green solid;
}


button {
    align-items: center;
    background-color: #FFFFFF;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
    margin: 5px;
}

button:hover, button:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

button:hover {
    transform: translateY(-1px);
}

button:active {
    background-color: #F0F0F1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
}
